/*
 * File: material.service.ts
 * Project: galilea-centro
 * File Created: Thursday, 1st October 2020 4:37:40 pm
 * Author: Jurgen Heysen (jurgen@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult, gql } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphqlService } from './graphql.service';
import { QueryRef } from 'apollo-angular';
import { MaterialType } from '@app/models/materialItems';
import { OperationParams } from '.';
import {
  GetCubicatedMaterialsParams,
  Material,
  MaterialCategory,
  MaterialFormat,
  MaterialStatus,
  MaterialSubcategory,
  MaterialsParams,
} from '@app/models';

export type MaterialOptions = {
  id: number;
  name: string;
  measurement: string;
  color: string;
  side: string;
  displayName: string;
  nickName: string;
  isGravel: boolean;
  stockable: boolean;
  deliveryFormat: {
    id: number;
    name: string;
  };
  materialFormats: {
    id: number;
    name: string;
    conversion: number;
  }[];
  stock?: number;
};

export type MaterialOptionsResponse = {
  materials: MaterialOptions[];
};

interface CubicatedMaterialResponse {
  cubicatedMaterials: MaterialOptions[];
}

interface MaterialQuery {
  material: Material;
}
interface MaterialsQuery {
  materials: Material[];
}
interface MaterialsFormatsQuery {
  materialFormats: MaterialFormat[];
}
interface MaterialsCategoriesQuery {
  materialCategories: MaterialCategory[];
}
interface MaterialsSubcategoriesQuery {
  materialSubcategories: MaterialSubcategory[];
}
interface CreateMaterialQuery {
  createMaterial: Material;
}
interface UpdateMaterialQuery {
  updateMaterial: Material;
}
interface DisableMaterialQuery {
  disableMaterial: boolean;
}
interface EnableMaterialQuery {
  enableMaterial: boolean;
}
interface DeleteMaterialQuery {
  deleteMaterial: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MaterialService {
  constructor(private graphql: GraphqlService) {}

  materialSubscription(materialId: number): QueryRef<MaterialQuery> {
    return this.graphql.query<MaterialQuery>(this.materialQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        params: {
          materialId,
        },
      },
    });
  }

  materialQuery = gql`
    query material($params: MaterialParams) {
      material(params: $params) {
        id
        name
        status
        enabled
        quantificationMetric
        deliveryFormat {
          id
          name
        }
        subcategory {
          id
          name
          category {
            id
            name
          }
        }
        isGravel
        comment
        stockable
        materialFormats {
          id
          name
        }
        updatedBy {
          id
          name
        }
        updatedAt
        requestedBy {
          id
          name
        }
        requestedAt
        approvedBy {
          id
          name
        }
        approvedAt
      }
    }
  `;

  materialsPriceAgreementQuery = gql`
    query GetMaterialsQuery($params: GetMaterialsParams) {
      materials(params: $params) {
        id
        name
        subcategory {
          name
          category {
            name
          }
        }
        materialFormats {
          name
        }
      }
    }
  `;

  materialsQuery = gql`
    query GetMaterialsQuery($params: GetMaterialsParams) {
      materials(params: $params) {
        id
        name
        deliveryFormat {
          id
          name
        }
        quantificationMetric
        subcategory {
          id
          name
          category {
            id
            name
          }
        }
        materialFormats {
          name
        }
        isGravel
        stockable
        enabled
        requestedBy {
          name
        }
        createdBy {
          name
        }
      }
    }
  `;

  materialsSearchQuery = gql`
    query GetMaterialsQuery($params: GetMaterialsParams) {
      materials(params: $params) {
        id
        name
        deliveryFormat {
          id
          name
        }
        subcategory {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  `;

  materialOptions = gql`
    query GetMaterialsQuery($params: GetMaterialsParams) {
      materials(params: $params) {
        id
        name
        measurement
        color
        side
        displayName
        nickName
        isGravel
        stockable
        deliveryFormat {
          id
          name
        }
        materialFormats {
          id
          name
          conversion
        }
      }
    }
  `;
  materialOptionsWithStock = gql`
    query GetMaterialsQuery($params: GetMaterialsParams) {
      materials(params: $params) {
        id
        name
        measurement
        color
        side
        displayName
        nickName
        isGravel
        stockable
        deliveryFormat {
          id
          name
        }
        materialFormats {
          id
          name
          conversion
        }
        stock
      }
    }
  `;

  materialsSubscription(params: MaterialsParams): QueryRef<MaterialsQuery> {
    return this.graphql.query<MaterialsQuery>(this.materialsQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        params,
      },
    });
  }

  materialsSearchSubscription(params: MaterialsParams): QueryRef<MaterialsQuery> {
    return this.graphql.query<MaterialsQuery>(this.materialsSearchQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        params,
      },
    });
  }

  materialsPriceAgreementSubscription(params: MaterialsParams): QueryRef<MaterialsQuery> {
    return this.graphql.query<MaterialsQuery>(this.materialsPriceAgreementQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        params,
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getMaterialOptionsWithStock(params?: MaterialsParams) {
    const materialQuery = this.graphql.lazyQuery<MaterialOptionsResponse, OperationParams<MaterialsParams>>(
      this.materialOptionsWithStock,
      {
        fetchPolicy: 'network-only',
        variables: {
          params: { types: [MaterialType.MATERIAL], ...params },
        },
      },
    );
    return materialQuery;
  }

  getMaterialOptions() {
    const materialQuery = this.graphql.lazyQuery<MaterialOptionsResponse, OperationParams<MaterialsParams>>(
      this.materialOptions,
      {
        fetchPolicy: 'cache-first',
        variables: {
          params: { types: [MaterialType.MATERIAL] },
        },
      },
    );
    return materialQuery;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  forceMaterialOptions() {
    const materialQuery = this.graphql.lazyQuery<MaterialOptionsResponse>(this.materialOptions, {
      fetchPolicy: 'network-only',
    });
    return materialQuery;
  }

  getCubicatedMaterials(params: GetCubicatedMaterialsParams): QueryRef<CubicatedMaterialResponse> {
    return this.graphql.query<CubicatedMaterialResponse>(this.budgetQuery, {
      fetchPolicy: 'cache-and-network',
      variables: {
        params,
      },
    });
  }

  getPendingMaterials(params: GetCubicatedMaterialsParams): Promise<ApolloQueryResult<CubicatedMaterialResponse>> {
    return this.graphql
      .lazyQuery<CubicatedMaterialResponse>(this.budgetQuery, {
        fetchPolicy: 'no-cache',
        variables: {
          params,
        },
      })
      .toPromise();
  }

  budgetQuery = gql`
    query cubicatedMaterials($params: GetCubicatedMaterialsParams) {
      cubicatedMaterials(params: $params) {
        id
        name
        deliveryFormat {
          id
          name
        }
        materialFormats {
          id
          name
        }
      }
    }
  `;

  getMaterialFormats(): QueryRef<MaterialsFormatsQuery> {
    const query = gql`
      query materialFormats {
        materialFormats {
          id
          name
          baseUnit
        }
      }
    `;
    return this.graphql.query<MaterialsFormatsQuery>(query, { fetchPolicy: 'cache-and-network' });
  }

  getMaterialCategories(): QueryRef<MaterialsCategoriesQuery> {
    const query = gql`
      query materialCategories {
        materialCategories {
          id
          name
        }
      }
    `;
    return this.graphql.query<MaterialsCategoriesQuery>(query, { fetchPolicy: 'cache-and-network' });
  }

  getMaterialSubcategories(): QueryRef<MaterialsSubcategoriesQuery> {
    const query = gql`
      query materialSubcategories {
        materialSubcategories {
          id
          name
          category {
            id
          }
        }
      }
    `;
    return this.graphql.query<MaterialsSubcategoriesQuery>(query, { fetchPolicy: 'cache-and-network' });
  }

  createMaterial(params: {
    materialFormats: string[];
    isGravel: null;
    deliveryFormatId: number;
    name: null;
    quantificationMetric: string;
    subcategoryId: number;
    comment: string;
    stockable: null;
  }): Observable<FetchResult<CreateMaterialQuery>> {
    return this.graphql.mutation<CreateMaterialQuery>(
      gql`
        mutation createMaterial($params: CreateMaterialParams) {
          createMaterial(params: $params) {
            id
          }
        }
      `,
      {
        variables: {
          params,
        },
      },
    );
  }

  updateMaterial(params: {
    materialFormats: string[];
    isGravel: null;
    deliveryFormatId: number;
    name: null;
    quantificationMetric: string;
    subcategoryId: number;
    comment: string;
    stockable: null;
    materialId: number;
  }): Observable<FetchResult<UpdateMaterialQuery>> {
    return this.graphql.mutation<UpdateMaterialQuery>(
      gql`
        mutation updateMaterial($params: UpdateMaterialParams) {
          updateMaterial(params: $params) {
            id
            materialFormats {
              id
              name
            }
          }
        }
      `,
      {
        variables: {
          params,
        },
        refetchQueries: [
          {
            query: this.materialQuery,
            variables: {
              params: {
                materialId: params.materialId,
              },
            },
          },
        ],
      },
    );
  }

  changeMaterialStatus(
    materialId: number,
    status: MaterialStatus,
  ): Observable<FetchResult<{ changeMaterialStatus: { status: MaterialStatus } }>> {
    return this.graphql.mutation<{ changeMaterialStatus: { status: MaterialStatus } }>(
      gql`
        mutation changeMaterialStatus($params: ChangeMaterialStatusParams) {
          changeMaterialStatus(params: $params) {
            status
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        variables: {
          params: {
            materialId,
            status,
          },
        },
        refetchQueries: [
          {
            query: this.materialQuery,
            variables: {
              params: {
                materialId,
              },
            },
          },
        ],
      },
    );
  }

  disableMaterial(materialId: number): Observable<FetchResult<DisableMaterialQuery>> {
    return this.graphql.mutation<DisableMaterialQuery>(
      gql`
        mutation disableMaterial($params: DisableMaterialParams) {
          disableMaterial(params: $params)
        }
      `,
      {
        variables: { params: { materialId } },
        refetchQueries: [
          {
            query: this.materialQuery,
            variables: {
              params: {
                materialId,
              },
            },
          },
        ],
      },
    );
  }

  enableMaterial(materialId: number): Observable<FetchResult<EnableMaterialQuery>> {
    return this.graphql.mutation<EnableMaterialQuery>(
      gql`
        mutation enableMaterial($params: EnableMaterialParams) {
          enableMaterial(params: $params)
        }
      `,
      {
        variables: { params: { materialId } },
        refetchQueries: [
          {
            query: this.materialQuery,
            variables: {
              params: {
                materialId,
              },
            },
          },
        ],
      },
    );
  }

  deleteMaterial(materialId: number): Observable<FetchResult<DeleteMaterialQuery>> {
    return this.graphql.mutation<DeleteMaterialQuery>(
      gql`
        mutation deleteMaterial($params: DeleteMaterialParams) {
          deleteMaterial(params: $params)
        }
      `,
      {
        variables: { params: { materialId } },
      },
    );
  }
}
